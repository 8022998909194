/**
 * Function to return the UTM params for items grid.
 * @returns UTM params
 */
const gridUTMParams = ( UtmString: string, gridName: string, index: number ) => {
  let finalUtm = '';
  if(gridName === 'talks-grid-search'){
    finalUtm = UtmString.replace('utm_term=search-result-', `utm_term=search-result-${index}`)
  }
  else if(gridName === 'talks-grid-knowledge-feed'){
    finalUtm = UtmString.replace('utm_term=channel-feed-result-', `utm_term=channel-feed-result-${index}`)
    finalUtm = finalUtm.replace('utm_term=smart-feed-result-', `utm_term=smart-feed-result-${index}`)
  }

  else{
    finalUtm = UtmString
  }

return finalUtm
}

export default gridUTMParams;
